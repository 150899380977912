import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
  static targets = ["tableActionsToggleable", "coverageFeatures", "unitWrapper", "selectAll",
                    "tariffIdField", "insurerIdField"]
  static values = { bulkEditUrl: String }

  initialize() {
    this.disconnect();
    validateFields();
    this.bindModalEvents();
  }

  toggleCheck(){
    const checked = document.getElementById('select_all').checked
    this.showCheckedCount(checked)
    this.showTableActions()
  }

  disconnect(){
    window.$(".create-coverage-feature-link").off('click');
    window.$("#new_coverage_feature").off('submit');
    window.$(".modal button.close").off('click');
    window.$(".modal a.btn-clean").off('click');
  }

  bindModalEvents(){
    this.bindCloseModalEvent();
    this.bindSubmitModalEvent();
    window.$(".create-coverage-feature-link").on('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const coverageFeatureName = $(e.currentTarget).data('value');
      this.showCoverageFeatureModal(coverageFeatureName);
    });
  }

  bindSubmitModalEvent(){
    window.$("#new_coverage_feature").on('submit', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const data = window.$(e.target).serializeJSON();
      const url = "/manager/coverage_features.json";
      const params = { method: "POST", headers: {  'Content-Type': 'application/json', 'Accept': 'json' }, body: JSON.stringify(data) }
      fetch(url, params)
        .then(response => response.json())
        .then(json => {
          this.closeCoverageFeaturesModal();
          this.disconnect();
          this.replaceCoverageFeatureValue(json);
        });
    });
  }

  replaceCoverageFeatureValue(json) {
    window.$(".coverage-feature-name").val(json.name_de);
    window.$("input[name='app_coverage_feature[coverage_feature_id]']").val(json.id);
  }

  bindCloseModalEvent(){
    window.$(".modal button.close").on('click', (e) => {
      this.closeCoverageFeaturesModal(e);
    });

    window.$(".modal a.btn-clean").on('click', (e) => {
      this.closeCoverageFeaturesModal(e);
    });
  }

  closeCoverageFeaturesModal(e){
    if(e)
      e.preventDefault();
    window.$('#coverage_form_modal').hide();
    window.$('.create-coverage-feature-link').remove();
  }

  showCoverageFeatureModal(coverageFeatureName){
    window.$("input[name='coverage_feature[name_de]']").val(coverageFeatureName)
    window.$("input[name='coverage_feature[short_name_de]']").val(coverageFeatureName)
    window.$("#coverage_form_modal").show();
  }
  updateSelect(event){
     switch(event.detail.name){
      case 'app_coverage_feature[value_type]':
        if (event.detail.value == 'number'){
          this.unitWrapperTarget.classList.remove('d-none');
        }
        else {
          this.unitWrapperTarget.classList.add('d-none');
        }
        break;
     }
  }

  selectAll(e) {
    const checked = e.target.checked;
    const checkboxes = new Array(...this.element.querySelectorAll('input[type=checkbox]'))
    checkboxes.forEach((el) => { el.checked = checked })

    this.showCheckedCount(checked)
    this.showTableActions()
  }

  showCheckedCount(checked) {
    const countSpan = document.getElementById('selected_records')
    let count = document.querySelectorAll('input[type="checkbox"]:checked').length
    if (checked) { count -=1  }
    countSpan.innerText = count
  }

  showTableActions() {
    const selected = document.querySelectorAll('input[type="checkbox"]:checked')
    if (selected.length > 0) {
      this.tableActionsToggleableTarget.classList.remove('hide');
      this.tableActionsToggleableTarget.classList.add('show');
    }
    else {
      this.tableActionsToggleableTarget.classList.add('hide');
      this.tableActionsToggleableTarget.classList.remove('show');
    }
  }

  batchUpdate(event) {
    event.preventDefault()
    event.stopPropagation()

    let selectedIds = [];
    const checkboxes = document.querySelectorAll('input[type="checkbox"]:checked')
    checkboxes.forEach(checkbox => {
      if (checkbox != this.selectAllTarget) {
        selectedIds.push(checkbox.value);
      }
    });

    let url = new URL(event.currentTarget.href);

    let formData = new FormData();
    formData.append("app_coverage_feature_ids", selectedIds.join(","));

    fetch(url.toString(), {
      method: "POST",
      body: formData,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    }).then(response => response.text())
      .then(html => {
        window.location.reload()
    })
  }

  submitSearchForm() {
    const turboFrame = document.getElementById('app_coverage_feature_overview')
    this.addSpiner(turboFrame)

    clearTimeout(this.inputTimeout)
    this.inputTimeout = setTimeout(() => {
      this.search()
    }, 500)
  }

  addSpiner(el) {
    const spinner = '<div class="d-flex justify-content-center"><div class="spinner spinner-track spinner-primary spinner-lg mt-10 mb-10"></div></div>'
    el.innerHTML = spinner
  }

  search() {
    clearTimeout(this.searchTimeout)
    this.searchTimeout = setTimeout(() => {
      const params = {
        tariff_id: this.tariffIdFieldTarget.value,
        insurer_id: this.insurerIdFieldTarget.value,
      }
      const queryString = Object.keys(params)
        .map(function(key) {
          return key + '=' + params[key]
        })
        .join('&')
      const url = this.bulkEditUrlValue + '?' + queryString
      Turbo.visit(url, { frame: 'app_coverage_feature_overview' })
    }, 500)
  }

  enableFields(e) {
    e.preventDefault()
    const row = e.target.closest('.app-coverage-feature-row')
    const formElements = row.querySelectorAll('input, button, textarea, select')

    formElements.forEach(el => {
      el.disabled = false
      if (el.classList.contains('disabled')) { el.classList.remove('disabled') }
      if (el.id.includes('score')) { el.readOnly = true }
    })
  }

  removeFeature(e) {
    e.preventDefault()
    e.stopPropagation()

    let parentElement = e.currentTarget.parentElement;
    let id = e.currentTarget.dataset.coverageFeatureId;
    let idElement = document.getElementById(`tariff_coverage_features_${id}_id`)
    if (idElement){
      const name = idElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      parentElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove()
  }
}
