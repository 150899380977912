import { Controller } from '@hotwired/stimulus'
import { validateFields } from '../../utils/form_utils';

export default class extends Controller {
  static targets = [
    "fieldType", "values",
    "fieldValueTypeWrapper", "valuesWrapper",
    "fieldCollectionType", "fieldTypeWrapper",
    "repeatable", "repeatFieldIdWrapper",
    "fieldTextWrapper", "defaultValueWrapper",
    "resourceTypeWrapper",
    "defaultValue", "defaultValueBoolean",
    "noteType", "noteFieldType"
  ]

  static values = { fieldValueAddUrl: String }

  connect() {
    validateFields();
    if (this.targets.find("fieldType")) {
      this.bookingFieldTypeChanged(this.fieldTypeTarget.value, true);
      this.typeChanged(this.fieldCollectionTypeTarget.value);
      this.repeatableChanged(this.repeatableTarget.checked);
    }
      if (this.fieldCollectionTypeTarget){
        this.resourceTypeDisplay();
      }
  }

  resourceTypeDisplay() {
    if(this.fieldCollectionTypeTarget.value === 'resource_type') {
      this.resourceTypeWrapperTarget.classList.remove("d-none");
    } else {
      this.resourceTypeWrapperTarget.classList.add("d-none");
    }
  }

  repeatableChanged(value){
    if(value){
      this.repeatFieldIdWrapperTarget.classList.remove("d-none");
    }
    else{
      this.repeatFieldIdWrapperTarget.classList.add("d-none");
    }
  }

  toggleRepeatable(event){
    this.repeatableChanged(event.target.checked);
  }

  addFieldValue(event){
    event.preventDefault();
    event.stopPropagation();
    const headers = { "X-Requested-With": "XMLHttpRequest" }
    const url = new URL(this.fieldValueAddUrlValue, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        let elementsCount = this.valuesWrapperTarget.childElementCount;
        let newIndex =  elementsCount === 0 ? 0 : elementsCount + 1
        html = html.replaceAll("0", newIndex.toString());
        this.valuesWrapperTarget.insertAdjacentHTML('beforeend', html);
      });
  }

  removeFieldValue(event){
    event.preventDefault();
    event.stopPropagation();
    let parentElement = event.currentTarget.parentElement.parentElement.parentElement;
    let nextElement = parentElement.nextElementSibling;

    if (nextElement && nextElement.tagName == "INPUT" && nextElement.type == "hidden"){
      const id = nextElement.value;
      const name = nextElement.name.replace("id", "_destroy")
      const removeTag = "<input type='hidden' name='" + name + "' value='1'>";
      nextElement.insertAdjacentHTML('afterend', removeTag);
    }
    parentElement.remove();
  }

  updateSelect(event){
    switch(event.detail.name){
      case "booking_field[field_type]":
        this.bookingFieldTypeChanged(event.detail.value);
        break;
      case "booking_field[type]":
        this.typeChanged(event.detail.value);
        break;
    }
  }

  typeChanged(value) {
    switch(value) {
      case "custom":
        this.showFieldType();
        break;
      default:
        this.hideFieldType();
    }
  }

  bookingFieldTypeChanged(value, init = false){
    switch(value){
      case "collection":
        this.showValues();
        break;
      case "radio":
        this.showValues();
        break;
      case "boolean":
        this.showFieldTextValue();
        break;
      case "note":
        this.showNoteType();
        break;
      default:
        this.hideValues();
        this.noteFieldTypeTarget.classList.add("d-none");
        break;
    }
  }

  showNoteType(){
    this.hideValues();
    this.noteFieldTypeTarget.classList.remove('d-none');
  }

  showFieldTextValue(){
    this.defaultValueTarget.name = "";
    this.defaultValueWrapperTarget.classList.add("d-none");
    this.fieldTextWrapperTarget.classList.remove("d-none");
    this.valuesWrapperTarget.classList.add("d-none");
    this.noteFieldTypeTarget.classList.add("d-none");
  }

  hideFieldType(){
    this.fieldTypeWrapperTarget.classList.add("d-none");
  }
  showFieldType(){
    this.fieldTypeWrapperTarget.classList.remove("d-none");
  }

  hideValues(){
    this.fieldTextWrapperTarget.classList.add("d-none");
    this.defaultValueWrapperTarget.classList.remove("d-none");
    this.valuesWrapperTarget.classList.add("d-none");
  }

  showValues(){
    this.fieldTextWrapperTarget.classList.add("d-none");
    this.noteFieldTypeTarget.classList.add("d-none");
    this.defaultValueWrapperTarget.classList.remove("d-none");
    this.valuesWrapperTarget.classList.remove("d-none");
  }
}
