import { Controller } from '@hotwired/stimulus'
import { showNotification } from '../../utils/bootstrap_notify';

export default class extends Controller {
  static targets = ['tab', 'link']

  toggleTab(e) {
    this.changeLinks(e)
    this.changeTabs(e)
  }

  submit(e) {
    e.preventDefault()
    e.stopPropagation()

    const row = e.target.parentElement.parentElement
    const form = row.querySelector('form')

    if (form) {
      const formData = new FormData(form)
      const inputs = row.querySelectorAll('input')

      inputs.forEach(input => {
        formData.append(input.name, input.value)
      })

      const refNo = formData.get("agency_credential[ext_reference]").trim()
      if (!refNo) {
        return showNotification("Referenz muss ausgefüllt werden", 'danger')
      }

      fetch(form.action, {
        method: 'POST',
        body: formData,
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
      .then(response => response.text())
      .then(() => {
        showNotification("Untervermittler anmeldedaten wurden gespeichert!", 'success')
      })
    }
  }

  changeLinks(e) {
    this.linkTargets.forEach(link => {
      link.classList.remove('active')
    })
    const targetLink = e.target.parentElement
    targetLink.classList.add('active')
  }

  changeTabs(e) {
    this.tabTargets.forEach(tab => {
      tab.classList.remove('active')
    })
    const tabId = e.target.dataset.tabId
    const targetTab = this.tabTargets.find((tab) => tab.id === tabId);
    if (targetTab) {
      targetTab.classList.add('active')
    }
  }
}