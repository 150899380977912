import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manager--tiny-editor"
export default class extends Controller {
  static targets = ["textarea"]
  
  connect() {
    this.textareaTargets.forEach((textarea) => {
      this.initializeTinyMCE(textarea)
    });
  }

  disconnect() {
    this.textareaTargets.forEach((textarea) => {
      if (textarea.editor) {
        textarea.editor.remove();
      }
    });
  }

  initializeTinyMCE(el) {
    const isDisabled = el.hasAttribute('disabled')

    tinymce.init({
      selector: `#${el.id}`,
      plugins: ["image", "link", "code", "lists", "media", "preview", "anchor", "wordcount", "table", "quickbars"],
      toolbar: [
        "blocks | bold italic underline strikethrough | alignleft aligncenter alignright | bullist numlist | undo redo",
      ].join(" "),
      branding: false,
      license_key: 'gpl',
      quickbars_insert_toolbar: "",
      quickbars_selection_toolbar: "bold italic underline | link blockquote",
      menu: {
        format: {
          title: 'Format',
          items: 'bold italic underline strikethrough | forecolor backcolor'
        }
      },
      readonly: isDisabled,
      disabled: isDisabled,
      setup: (editor) => {
        editor.on('init', () => {
          setTimeout(() => {
            const promotionLinks = document.getElementsByClassName('tox-promotion-link');
            promotionLinks.forEach((link) => {
              link.classList.add('d-none');
            })
          }, 50);
        });
      },
    });
  }
}
