import 'bootstrap-notify';


export function showNotification(message, type) {
    window.$.notify({
      message: message,
    }, {
      type: type, // Possible notification types (success, warning, danger, info)
      placement: {
        from: 'top',
        align: 'center',
      },
      offset: {
        y: 40
      },
      allow_dismiss: false,
      delay: 5000,
      animate: {
        enter: 'animated fadeInDown',
        exit: 'animated fadeOutUp',
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
                  '<span data-notify="icon"></span> ' +
                  '<span data-notify="title">{1}</span> ' +
                  '<span data-notify="message" class="h5">{2}</span>' +
                  '<div class="progress" data-notify="progressbar">' +
                    '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>' +
                  '</div>' +
                  '<a href="{3}" target="{4}" data-notify="url"></a>' +
                '</div>',
    });
  }
